import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from './translate/translate.service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  notificationSubject = new ReplaySubject<{error?: HttpErrorResponse | string, http?: 'Start' | 'End', body?: any, warn?: string, success?: string}>();
  constructor(snack: MatSnackBar, translate: TranslateService) { 
    this.notificationSubject.subscribe(event => {
      snack.dismiss();
      if (event?.error) {
        if (isHttpErrorResponse(event.error)) {
          snack.open(translate.translate(event.error.message), 'OK', {panelClass: 'warning'})._dismissAfter(5000)
        } else {
          snack.open(translate.translate(event.error), 'OK', {panelClass: 'warning'})._dismissAfter(5000)
        }
        /* .afterOpened().pipe(debounceTime(5000)).subscribe(() => {
          snack.dismiss();
        }) */;
      } else if (event.warn || event.success) {
        snack.open(translate.translate(event.warn || event.success), 'OK', {panelClass: 'success'})._dismissAfter(5000)
      }
    })
  }
}
function isHttpErrorResponse(error: HttpErrorResponse | string): error is HttpErrorResponse {
  return !!(error as HttpErrorResponse)?.message;
}

