import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, filter, catchError, finalize } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { LoginService } from './login/login.service';

@Injectable()
export class InterceptorService implements HttpInterceptor {
  constructor(private noti: NotificationService, private login: LoginService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.noti.notificationSubject.next({http: 'Start'});
    // req = req.clone({headers: req.headers.append('authorization', this.login.user || '')})
    return next.handle(req).pipe(
      filter(resp => resp instanceof HttpResponse),
      tap((resp: HttpResponse<unknown>) => resp.body ? this.handleEvent(resp) : null, (resp) => this.handleEvent(resp))
    );
  }

  private handleEvent(resp: any) {
    this.noti.notificationSubject.next(Object.assign(resp, {http: 'End'}));
  };
}
