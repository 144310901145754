import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpClient, HttpEvent, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, of, Subject } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { PageEvent } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { FindQuery, ListDto } from 'backend/src/app/endpoints/common.model.dto';

@Component({
  selector: 'cmed-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements OnInit {
  /**
   * If provided, the default click action is to navigate to detailUrl + id
   */
  @Input()
  detailUrl: string;
  @Input()
  manualPager: Subject<void>;
  @Input()
  apiService: ApiService;
  isLoading: boolean;
  @Input()
  isGeneric: boolean = true;
  private _dataSourceOverride: Observable<ListDto>;
  public get dataSourceOverride(): Observable<ListDto> {
    return this._dataSourceOverride;
  }
  @Input()
  public set dataSourceOverride(value: Observable<ListDto>) {
    this._dataSourceOverride = value;
  }
  @Input()
  public set apiUrl(value: string) {
    this._apiUrl = value;
    this.page();
  }
  private _apiUrl: string;
  public get apiUrl(): string {
    return this._apiUrl;
  }
  
  private displayedColumnsValue: {name: string; type?: string}[] = [];
  displayedColumnNames: string[] = [];
  private _displayedColumns = new Proxy(this.displayedColumnsValue, {
    set: (target, property, value) => {
      target[property] = value;
      this.displayedColumnNames.length = 0;
      this.displayedColumnNames.push(...target.map(o => o.name));
      return true;
    }
  });
  public get displayedColumns() {
    return this._displayedColumns;
  }
  @Input()
  public set displayedColumns(value) {
    this._displayedColumns.length = 0;
    this._displayedColumns.push(...value)
  }
  dataSource: Observable<ListDto>;
  @Output()
  dataSourceChange = new EventEmitter<ListDto>();
  @Output()
  rowClick = new EventEmitter<any>();
  resultsLength = 0;
  lastpageEvent: PageEvent;
  @Input()
  simpleView: boolean;
  @Input()
  public set filter(value: string) {
    this._filter = value;
    this.page()
  }
  private _filter: string = null;
  public get filter(): string {
    return this._filter;
  }
  propertyFilter: any = {};

  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit(): void {
    this.manualPager?.subscribe(() => this.page());
    this.page();
  }
  
  page(event?: PageEvent) {
    if (!this.apiUrl && !this.apiService) {
      return;
    }
    if (!event) {
      event = {
        pageIndex: 0,
        pageSize: 20
      } as PageEvent;
    }
    const params: {[param: string]: string} = {
      page: event.pageIndex + 1 as any,
      limit: event.pageSize as any,
    };
    params.filter = JSON.stringify(this.propertyFilter);
    if (this.filter) { // FIXME
      params.search = this.filter;
    }
    this.isLoading = true;
    this.dataSource = this.setDataSource(params);
  }

  private setDataSource(params: {[param: string]: string}) {
    const httpObs = this.apiUrl ? 
    this.http.get<ListDto>(this.apiUrl, { params: params }) :
    this.apiService(params);
    return httpObs
    .pipe(tap(data => this.resultsLength = data.listLength), switchMap(data => {
      this.isLoading = false;
      data.data.forEach(row => {
        if (!row.data) {
          row.data = Object.assign({}, row) as any;
        }
        Object.assign(row.data, row.metaData)
      });
      this.dataSourceChange.emit(data);
      return of(data);
    }))
  }

  rowClickHandler(row: any) {
    if (this.detailUrl){
      this.router.navigate([this.detailUrl, row._id]);
    } else {
      this.rowClick.emit(row);
    }
  }

  applyFilter(event: KeyboardEvent) {
    // this.filter = (event.target as HTMLInputElement).value;
    this.page(this.lastpageEvent)
  }
  setPropertyFilter(col: any, event: InputEvent) {
    this.propertyFilter[`${this.isGeneric ? 'data.' : ''}${col.name}`] = {$regex: `${(event.target as HTMLInputElement).value}`, $options: 'i'};
    this.page(this.lastpageEvent)
  }

}

type ApiService = 
((findQuery: FindQuery, observe: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}) => Observable<ListDto>) &
((findQuery: FindQuery, observe: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}) => Observable<HttpResponse<ListDto>>) &
((findQuery: FindQuery, observe: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}) => Observable<HttpEvent<ListDto>>) &
((findQuery: FindQuery) => Observable<ListDto>)
