import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatInputModule } from '@angular/material/input';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatButtonModule } from '@angular/material/button';
import { TranslatePipe } from './pipes/translate.pipe';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TableComponent } from './components/table/table.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { FormComponent } from './components/form/form.component';
import { CapitalcasePipe } from './pipes/capitalcase.pipe';

const declarations = [
  TableComponent,
  TranslatePipe,
  FormComponent,
  CapitalcasePipe,
]

const MaterialDependencies = [
  MatInputModule,
  MatSidenavModule,
  MatProgressSpinnerModule,
  MatButtonModule,
  MatNativeDateModule,
  MatPaginatorModule,
  MatSelectModule,
  MatCardModule,
  MatToolbarModule,
  MatDatepickerModule,
  MatListModule,
  MatTableModule,
  MatDialogModule,
]

@NgModule({
  declarations,
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ...MaterialDependencies
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ...MaterialDependencies,
    ...declarations
  ],
})
export class CmedCommonModule { }
