/**
 * Cmed example
 * The cmed API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateDto { 
    _id?: string;
    id?: number;
    createdAt?: string;
    modifedAt?: string;
    data?: object;
    metaData?: object;
}

