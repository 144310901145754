import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanLoad, Route, UrlSegment, CanActivateChild, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginService } from './login.service';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class LoginGuard implements CanActivateChild {
  constructor(private loginservice: LoginService, private router: Router){}
  lastRoute: ActivatedRouteSnapshot;
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.loginservice.loggedIn.pipe(map(v => {
      if (!v && !this.lastRoute) {
        this.lastRoute = next;
      } else if (v && this.lastRoute) {
        this.lastRoute = null;
        return this.router.parseUrl(this.lastRoute?.toString());
      }
      return !!v
    }));
  }
  
}
