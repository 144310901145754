import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';

export type FormControlType = 'date' | 'number' | 'textarea' | 'text' | 'dropdown' | 'boolean';

export interface FormPropertyMeta {
  name: string;
  type: FormControlType;
  list?: {
    label: string;
    value: string;
  }[];
}

@Component({
  selector: 'cmed-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent {
  @Input()
  form: FormGroup;
  @Input()
  propertyList: FormPropertyMeta[];
  @Output()
  saveEmitter = new EventEmitter<void>();
  @Output()
  removeEmitter = new EventEmitter<void>();

  constructor() { }

  save() {
    this.saveEmitter.emit();
  }
  remove() {
    this.removeEmitter.emit();
  }

}
