import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../../translate/translate.service';

@Pipe({
  name: 'translate'
})
export class TranslatePipe implements PipeTransform {

  constructor(private translate: TranslateService) {}

  transform(value: string, ...args: unknown[]): string {
    return this.translate.translate(value) || value;
  }

}
