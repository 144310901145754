import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TranslateService {
  private mapper = {
    hu: {
      new: 'új',
      add: 'hozzáadás',
      users: 'felhasználók',
      remove: 'törlés',
      select: 'kiválasztás',
      unselect: 'kiválasztás törlése',
      logout: 'kijelentkezés',
      save: 'mentés',
      firstName: 'keresztnév*',
      lastName: 'vezetéknév*',
      dateOfBirth: 'születési dátum*',
      placeOfBirth: 'születési hely*',
      numberOfVisits: 'vizitek száma',
      patients: 'páciensek',
      consultations: 'rendelések',
      selectedPatient: 'kiválasztott páciens',
      cause: 'vizit oka',
      description: 'leírás',
      patientId: 'páciens azonosító',
      date: 'dátum',

      title: 'titulus',
      maidenName: 'leánykori név',
      mothersName: 'anyja neve',
      gender: 'neme',
      language: 'anyanyelv',
      TAJ: 'taj szám',
      address: 'cím',
      phone: 'telefon',
      mobile: 'mobil',
      email: 'e-mail*',
      street: 'utca',
      city: 'város',
      county: 'megye',
      postCode: 'irányítószám',
      country: 'ország',

      summary: 'összegzés',
      diagnosis: 'diagnózis',
      anamnesis: 'anamnézis',
      instrumentalExam: 'műszeres vizsgálat',
      physicalExam: 'fizikális vizsgálat',
      service: 'műszeres vizsgálat',
      search: 'keresés',
      'Invalid token.': 'Kérjük jelentkezzen be!',
      'User not found.': 'A felhasználó nem létezik vagy nincs aktiválva!',
      'User is not active!': 'A felhasználó rövid időn belül aktiválva lesz!',
      submit: 'Mehet',
      login: 'Váltás bejelentkezésre',
      register: 'Váltás regisztrációra',
      name: 'név',
      password: 'jelszó',
      'add new': 'új hozzáadása',
      administration: 'adminisztráció',

      'eeszt-news': 'eeszt hírek',
      'get eeszt data': 'eeszt adatok lekérdezése',
    }
  };

  constructor(private http: HttpClient) {
    try {
      this.http.get('assets/data.json');
    } catch (error) {
      console.error(error);
    }
   }

   translate(text: string): string {
     return this.mapper.hu[text] || text;
   }
}
