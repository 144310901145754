<!-- <mat-form-field *ngIf="!simpleView">
    <mat-label>{{'search' | translate | capitalcase }}</mat-label>
    <input matInput [(ngModel)]="filter" (keyup)="applyFilter($event)" type="text">
</mat-form-field> -->
<div class="table-container" [class.loading]="isLoading">
    <table mat-table [dataSource]="dataSourceOverride || (dataSource | async)?.data" class="mat-elevation-z8">
        <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col.name" [ngSwitch]="col.type">
            <th mat-header-cell *matHeaderCellDef>
                <mat-label>{{ col.name | translate | capitalcase }}</mat-label>
                <input matInput [placeholder]="'search' | translate | capitalcase " (input)="setPropertyFilter(col, $event)" type="text"/>
            </th>
            <ng-container *ngSwitchCase="'date'">
                <td mat-cell *matCellDef="let row">{{ row.data[col.name] | date  }}</td>
            </ng-container>
            <ng-container *ngSwitchDefault>
                <td mat-cell *matCellDef="let row">{{ row.data[col.name] }}</td>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumnNames; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnNames;" (click)="rowClickHandler(row)"></tr>
    </table>
    <mat-card *ngIf="isLoading" 
    style="display: flex; justify-content: center; align-items: center">
    <mat-progress-spinner 
        color="primary" 
        mode="indeterminate">
    </mat-progress-spinner>
    </mat-card>
</div>
<mat-paginator *ngIf="!simpleView" (page)="page($event)" [pageSizeOptions]="[20, 50, 100]" [length]="resultsLength" [pageSize]="20">
</mat-paginator>