/**
 * Cmed example
 * The cmed API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateConsultationDtoData { 
    date?: string;
    cause?: string;
    anamnesis?: string;
    physicalExam?: string;
    instrumentalExam?: string;
    diagnosis?: string;
    service?: string;
    description?: string;
    summary?: string;
}

