import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Form } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginFormComponent } from './login-form/login-form.component';
import { CmedService, UpdateUserDto, UserDataDto } from 'generated-sources/openapi';

@Injectable()
export class LoginService {
  loggedIn = new BehaviorSubject<any>(false); // FIXME
  user: any = localStorage.getItem('token');
  userData = new BehaviorSubject<UpdateUserDto>({data: {name: 'Anonymus'}} as UpdateUserDto);
  environment: 'tst' | 'dev' | 'prod';

  constructor(private http: CmedService, private router: Router, private activatedRoute: ActivatedRoute) { }

  getUser() { // FIXME
    const userObj = this.user?.split('.')[1];
    if (!userObj) {
      return;
    }
    const userObject: UserDataDto = JSON.parse(atob(userObj));
    console.log(userObject);

    this.http.configuration.credentials = {bearer: this.user};
    this.http.configuration.withCredentials = true;
    
    const userCall = this.http.userControllerFindOne(userObject.sub).pipe(catchError(err => {
      this.loggedIn.next(false);
      throw new Error(err?.message);
    }), tap(data => this.userData.next(data)));
    userCall.pipe().subscribe(() => this.success(this.user))
    return this.loggedIn;
  }
  
  registerUser(username: string, email: string, password: string) {
    this.http.appControllerRegister(username, email, password).subscribe(data => {
      window.location.reload(); // FIXME
    })
    /* this.http.post('/api/users', form, {responseType: 'text'}).subscribe(data => {
      this.success(data);
      this.getUser();
    }) */
  }
  
  loginUser(username: string, password: string) {
    this.http.appControllerLogin(username, password).subscribe(data => {
      this.success(data.access_token);
      this.getUser();
    })
  }
  
  logoutUser() {
    this.loggedIn.next(false);
    this.user = null;
    localStorage.setItem('token', null);
    this.userData.next(null);
  }
  
  private success(data: string) {
    this.loggedIn.next(data);
    localStorage.setItem('token', data);
    this.user = data;
    this.getEnvironment();
    const userString = JSON.stringify(data) || data;
    console.log(`User logged in: ${userString}`);
    if (!this.router.getCurrentNavigation()) {
      this.router.navigate(['/main']);
    }
  }
  getEnvironment() {
    // this.http.get('/api/users/environment').subscribe((env: {environment: 'tst' | 'dev' | 'prod'}) => this.environment = env.environment);
  }
}
