import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'capitalcase'
})
export class CapitalcasePipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    return value ? value[0].toUpperCase() + value.substring(1) : '';
  }

}
