import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './login/login.guard';
import { LoginModule } from './login/login.module';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: 'main',
    canActivateChild: [ LoginGuard ],
    loadChildren: () => import('./main/main.module')
    .then(m => m.MainModule)
   },
    {
      path: '**',
      redirectTo: 'main'
    },
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: !environment.production}), LoginModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
