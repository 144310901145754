import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CmedCommonModule } from './common-component/cmed-common.module';
import { LoginModule } from './login/login.module';
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { ApiModule, BASE_PATH } from 'generated-sources/openapi';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { InterceptorService } from './interceptor.service';

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    ApiModule,
    MatSnackBarModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    // CmedCommonModule,
    LoginModule,
  ],
  exports: [
  ],
  providers: [
    {provide: BASE_PATH, useValue: location.origin},
    {
      provide: HTTP_INTERCEPTORS,
      multi: true,
      useClass: InterceptorService
    },
    /* {provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy} */
    // https://github.com/angular/angular/issues/12446#issuecomment-312754035
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
