import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { LoginService } from '../login.service';

@Component({
  selector: 'cmed-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  form: FormGroup;
  showLogin: boolean = true;

  constructor(private login: LoginService) { }

  ngOnInit(): void {
    const builder = new FormBuilder();
    this.form = builder.group({
        name: '',
        password: '',
        email: '',
      }
    )
  }

  sendForm() {
    if (this.showLogin) {
      this.login.loginUser(this.form.value.email, this.form.value.password); 
    } else {
      this.login.registerUser(this.form.value.name, this.form.value.email, this.form.value.password); 
    }
  }

  toggleLogin() {
    this.showLogin = !this.showLogin;
  }

}
