import { Component, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';
import { map, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LoginService } from './login/login.service';

@Component({
  selector: 'cmed-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'cmed';
  message: string;
  loggedIn = this.login.getUser();

  constructor(public notif: NotificationService, private login: LoginService) {}

  ngOnInit(): void {
    this.notif.notificationSubject
    .pipe(filter(message => message && message !== this.message), map(message => message.body?.message))
    .subscribe(data => (this.message = data, setTimeout(() => (this.message = null), 3000)));
  }
}
