<form [formGroup]="form"  (submit)="sendForm()" *ngIf="form" #formRef>
    <div>
        <ng-container *ngIf="!showLogin">
            <mat-form-field>
                <mat-label>{{'name' | translate | capitalcase }}</mat-label>
                <input matInput formControlName="name" type="text"/>
            </mat-form-field>
        </ng-container>
        <mat-form-field>
            <mat-label>{{'email' | translate | capitalcase }}</mat-label>
            <input matInput formControlName="email" type="email"/>
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'password' | translate | capitalcase }}</mat-label>
            <input matInput formControlName="password" type="password" (keyup.enter)="sendForm()"/>
        </mat-form-field>
    </div>
    <div>
        <button mat-button type="submit">{{'submit' | translate}}</button>
        <button mat-button type="button" (click)="toggleLogin()">{{(!showLogin ? 'login' : 'register') | translate}}</button>
    </div>
</form>
