/**
 * Cmed example
 * The cmed API description
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreatePatientDtoData { 
    firstName?: string;
    lastName?: string;
    title?: string;
    maidenName?: string;
    dateOfBirth?: string;
    placeOfBirth?: string;
    mothersName?: string;
    gender?: CreatePatientDtoData.GenderEnum;
    TAJ?: string;
    street?: string;
    city?: string;
    county?: string;
    phone?: string;
    mobile?: string;
    email?: string;
    postCode?: string;
    country?: string;
}
export namespace CreatePatientDtoData {
    export type GenderEnum = 'male' | 'female';
    export const GenderEnum = {
        Male: 'male' as GenderEnum,
        Female: 'female' as GenderEnum
    };
}


