import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form/login-form.component';
import { LoginService } from './login.service';
import { LoginGuard } from './login.guard';
import { CmedCommonModule } from '../common-component/cmed-common.module';



@NgModule({
  declarations: [LoginFormComponent],
  imports: [
    CommonModule,
    CmedCommonModule,
  ],
  providers: [ LoginService, LoginGuard ],
  exports: [ LoginFormComponent ]
})
export class LoginModule { }
