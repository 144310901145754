<mat-card color="primary" *ngIf="form">
    <mat-card-actions>
        <button mat-button [disabled]="form.invalid || form.pristine" (click)="save()">{{'save' | translate | capitalcase}}</button>
        <button mat-button (click)="remove()">{{'remove' | translate | capitalcase}}</button>
        <ng-content></ng-content>
    </mat-card-actions>
    <mat-card-content>
        <form [formGroup]="form">
            <mat-form-field *ngFor="let elem of propertyList">
                <mat-label >{{elem.name | translate | capitalcase }}</mat-label>
                <input matInput *ngIf="elem.type === 'date'" [formControlName]="elem.name" [type]="'text'" [matDatepicker]="picker"/>
                <input matInput *ngIf="!['date', 'textarea', 'dropdown'].includes(elem.type)" [formControlName]="elem.name" [type]="elem.type"/>
                <textarea matInput *ngIf="elem.type === 'textarea'" [formControlName]="elem.name"></textarea>
                <mat-select matInput [formControlName]="elem.name" *ngIf="elem.type === 'dropdown'">
                    <mat-option *ngIf="!elem.list">
                        <mat-progress-spinner 
                            color="primary" 
                            mode="indeterminate">
                        </mat-progress-spinner>
                    </mat-option>
                    <mat-option *ngFor="let item of elem.list" [value]="item.value">{{item.label}}</mat-option>
                </mat-select>
                <mat-datepicker-toggle *ngIf="elem.type === 'date'" matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
            </mat-form-field>
        </form>
    </mat-card-content>
</mat-card>
